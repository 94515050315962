<template>
  <div class="flex-table-row flex-column accordion-request" >
    <RequestForm
      title="Редактирование заявки на списание"
      :is-update="true"
      :initial-model="{
        ...defaultRequestForm,
        id: request.id,
        points: request.points_debited,
        writeDownFor: request.description,
        actFilesExists: request.docs.filter((doc) => +doc.file_type === DOC_TYPE_ACT_FILE),
        otherFilesExists: request.docs.filter((doc) => +doc.file_type === DOC_TYPE_OTHER_FILE),
        accountFilesExists: request.docs.filter((doc) => +doc.file_type === DOC_TYPE_ACCOUNT_FILE),
      }"
      :open="toggleUpdateForm"
      @cancel="toggleUpdateFormHandle"
      @submit="submitRequestsHandle"
    />
    <div
      :id="'heading' + request.id"
      class="accordion-header flex-table-row"
    >
      <div
        class="flex-table-row collapsed accordion-toggle"
        role="button"
        data-toggle="collapse"
        :data-target="'#collapse' + request.id"
        aria-expanded="true"
        :aria-controls="'collapse' + request.id"
      >
        <div class="flex-table-cell">
          <b class="flex-table-cell__label d-lg-none">Статус:</b>
          <div :class="`requests-content-status`">
            <div :class="`${statusClasses[request.status]} requests-content-status-icon`"></div>
            <span>{{ statuses[request.status] }}</span>
          </div>
        </div>
        <div class="flex-table-cell">
          <b class="flex-table-cell__label d-lg-none">Дата создания:</b>
          <span>{{ request.date_request }}</span>
        </div>
        <div class="flex-table-cell">
          <b class="flex-table-cell__label d-lg-none">Номер заявки:</b>
          <span>{{ request.request_number }}</span>
        </div>
        <div class="flex-table-cell">
          <b class="flex-table-cell__label d-lg-none">Сумма списания:</b>
          <span>{{ request.points_debited | fractionSizeSpace }}
                  {{ makeDeclension(request.points_debited) }}</span>
        </div>
        <div class="flex-table-cell">
          <b class="flex-table-cell__label d-lg-none">Основание списания:</b>
          <span>{{ request.description }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="request.docs"
      :id="'collapse' + request.id"
      class="flex-table-row accordion-collapse collapse"
      :aria-labelledby="'heading' + request.id"
      data-parent="#accordion"
    >
      <div class="flex-table-cell flex-table-cell__docs px-0 py-0 w-100">
        <h3 class="accordion-title">Подтверждающие документы</h3>
        <div class="accordion-body">
          <div class="requests-content__accordion-docs">
            <FileCard
              v-for="(doc, index) in request.docs"
              class="requests-content__accordion-doc"
              :key="index"
              :name="doc.name"
              :link="getFileUrl(doc.docs_url)"
              :size="fileSizeFormatter(+doc.size) || '-'"
              :show-delete="false"
            />
          </div>
          <div class="accordion-body__actions">
            <button
              type="button"
              :disabled="request.status !== 3"
              class="btn btn-red"
              @click="toggleUpdateFormHandle"
            >
              Редактировать
            </button>
            <button
              :disabled="request.status !== 1"
              type="button"
              class="btn btn-red"
              @click="cancelRequestHandle"
            >
              Отменить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileCard from "@/components/profile/requests-write-down-points/FileCard.vue";
import RequestForm from "@/components/profile/requests-write-down-points/RequestForm.vue";
import {showConfirm, showSuccess} from "@/lib/swal2";
import {objectToFormData} from "@/utilities/requests-write-down-points";
import {defaultRequestForm} from "@/config/requestWriteDownPoints";
import {mapGetters} from "vuex";
import fileSizeFormatter from "@/utilities/file-size-formatter";

export default {
  name: 'RequestItem',
  components: {RequestForm, FileCard},
  props: {
    request: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      fileSizeFormatter,
      defaultRequestForm,
      toggleUpdateForm: false,
      DOC_TYPE_ACT_FILE: 0,
      DOC_TYPE_OTHER_FILE: 1,
      DOC_TYPE_ACCOUNT_FILE: 2,
      statuses: {
        1: 'Принят',
        2: 'Согласован',
        3: 'На доработке',
        4: 'Отменён',
        5: 'Доработан',
      },
      statusClasses: {
        1: 'processing',
        2: 'done',
        3: 'editing',
        4: 'cancelled',
        5: 'edited',
      },
    }
  },
  computed: {
  ...mapGetters('common', ['makeDeclension'])
  },
  methods: {
    getFileUrl(url) {
      return `${window.location.protocol}//${url}`
    },
    toggleUpdateFormHandle() {
      this.toggleUpdateForm = !this.toggleUpdateForm
    },
    submitRequestsHandle(form) {
      if (form.points > this.pointsNumber) {
        showSuccess("На аккаунте недостаточно бонусов");
        return;
      }
      this.$emit('update', objectToFormData(form));
      this.toggleUpdateForm = false
    },
    async cancelRequestHandle() {
      const { isConfirmed } = await showConfirm(
          'Вы действительно хотите отменить заявку?'
      );
      if (!isConfirmed) return;
      this.$emit('cancel', this.request.id);
    }
  }
}
</script>

<style scoped lang="scss">

.requests-content {
  &__accordion-docs {
    display: flex;
    flex-wrap: wrap;
  }

  &__accordion-doc {
    margin-bottom: 8px;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &-status {
    display: flex;
    align-items: center;
    position: relative;
    &-icon {
      min-width: 24px;
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
    & .processing,
    & .edited {
      background-image: url("../../../assets/images/work.svg");
      background-repeat: no-repeat;
    }
    & .done {
      background-image: url("../../../assets/images/correct.svg");
      background-repeat: no-repeat;
    }
    & .cancelled {
      background-image: url("../../../assets/images/cancel.svg");
      background-repeat: no-repeat;
    }
    & .editing {
      background-image: url("../../../assets/images/editing.svg");
      background-repeat: no-repeat;
    }
  }
}

.flex-table {
  display: flex;
  flex-direction: column;

  &-header {
    font-weight: bold;

    @media (max-width: 992px) {
      display: none !important;
    }
  }

  &-row {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 992px) {
      flex-direction: row;
    }

    &.collapse {
      display: none;
    }

    &.collapse.show {
      display: flex;
    }
  }

  &-cell {
    padding: 0.5rem;
    word-wrap: break-word;

    @media (min-width: 992px) {
      padding: 1rem;
      width: calc(100% / 4);
    }
    @media (max-width: 992px) {
      display: flex;
      align-items: center;
      &__label {
        margin-right: .284rem;
      }
      &__docs {
        flex-direction: column;
        align-items: baseline;
        & .accordion-title {
          margin: .5rem .57rem;
        }
        & .accordion-body {
          width: 100%;
          padding: 1rem .5rem;
        }
      }
    }
  }
}



.accordion-title {
  font-size: inherit;
  font-weight: bold;
  margin: 0 1rem .57rem;
}

.accordion-header {
  cursor: pointer;
  border-top: 1px solid #dee2e6;
}

.accordion {
  &-header {
    position: relative;

    .table {
      white-space: nowrap;
    }
  }

  &-toggle {
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 16px;
      border-style: solid;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(225deg);
      vertical-align: middle;
      color: #555;
    }
  }
}

.collapsed:after {
  transform: rotate(45deg);
}

.accordion-body {
  border-top: 2px solid #f5f5f5;
  border-bottom: 2px solid #f5f5f5;
  padding: 1rem;
  &__actions {
    display: flex;
    justify-content: flex-end;
    & .btn:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

</style>