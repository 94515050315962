<template>
    <div v-if="open" ref="requestOverlay" class="request-form__overlay" @click="outsideClickHandle">
      <ValidationObserver ref="validationObserver" slim v-slot="{ invalid, handleSubmit }">
        <form class="request-form" @submit.prevent="handleSubmit(submitHandle)">
          <div class="request-form__title">
            <h3 class="request-form__title-text">{{ title }}</h3>
            <button type="button" class="btn" @click="cancelHandle">
              <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px">
                <title/>
                <g id="cross">
                  <line fill="none" stroke="#545454" stroke-linecap="round" stroke-linejoin="round" stroke-width="2px" x1="7" x2="25" y1="7" y2="25"/>
                  <line fill="none" stroke="#545454" stroke-linecap="round" stroke-linejoin="round" stroke-width="2px" x1="7" x2="25" y1="25" y2="7"/>
                </g>
              </svg>
            </button>
          </div>
          <div class="request-form__content">
            <ValidationProvider
              class="d-block"
              :name="form.points.toString()"
              rules="required|integer|min_value:1"
              v-slot="{ errors }"
            >
              <AppInput
                :value="form.points"
                name="points"
                label="Сумма на списание *"
                @input="inputPointsHandle"
              />
              <span class="request-form__error d-block text-danger my-2">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              class="form-group d-block"
              :name="form.writeDownFor"
              :rules="`required|excluded:${defaultWriteDownFor}`"
              v-slot="{ errors }"
            >
              <AppInput
                :value="form.writeDownFor"
                name="writeDownFor"
                label="Списание баллов за (введите основание для списания) *"
                @input="inputWriteDownForHandle"
                @blur="blurWriteDownForHandle"
                @focus="focusWriteDownForHandle"
              />
              <span class="request-form__error d-block text-danger mt-2">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              class="form-group d-block"
              :rules="isUpdate && form.actFilesExists.length
                ? 'ext:pdf,png,jpeg,jpg,msg|maxSize:200000'
                : 'required|ext:pdf,png,jpeg,jpg,msg|maxSize:200000'"
              v-slot="{ errors, validate }"
            >
              <div>
                <label class="request-form__label" for="actFiles">Акт/накладная/протокол *</label>
                <input
                  type="file"
                  multiple
                  ref="actFiles"
                  class=" request-form__input"
                  id="actFiles"
                  name="actFiles"
                  @input="inputFilesHandle($event, 'actFiles')"
                  @change="validate"
                >
              </div>
              <span :class="{ 'd-block my-2 request-form__error': true, 'text-danger': errors.length }">
                {{ errors[0] || 'Форматы pdf, jpeg, jpg, png, msg. Не более 200 Мб' }}
              </span>
              <div
                v-if="form.actFilesExists.length || form.actFiles.length"
                class="request-form__content-files"
              >
                <FileCard
                  v-for="(file, index) in form.actFilesExists"
                  :key="index"
                  :name="file.name"
                  :size="fileSizeFormatter(+file.size)"
                  @delete="deleteFile(file, index, 'actFiles')"
                />
                <FileCard
                  v-for="(file, index) in form.actFiles"
                  :key="index"
                  :name="file.name"
                  :size="fileSizeFormatter(+file.size)"
                  @delete="deleteFile(file, index, 'actFiles')"
                />
              </div>
            </ValidationProvider>
            <ValidationProvider
              class="form-group d-block"
              :rules="isUpdate && form.accountFilesExists.length
              ? 'ext:pdf,png,jpeg,jpg,msg|maxSize:200000'
              : 'required|ext:pdf,png,jpeg,jpg,msg|maxSize:200000'"
              v-slot="{ errors, validate }"
            >
              <div>
                <label class="request-form__label" for="accountFiles">Счёт *</label>
                <input
                  type="file"
                  multiple
                  ref="accountFiles"
                  class=" request-form__input"
                  id="accountFiles"
                  name="accountFiles"
                  @input="inputFilesHandle($event, 'accountFiles')"
                  @change="validate"
                >
              </div>
              <span :class="{ 'd-block my-2 request-form__error': true, 'text-danger': errors.length }">
                {{ errors[0] || 'Форматы pdf, jpeg, jpg, png, msg. Не более 200 Мб' }}
              </span>
              <div
                v-if="form.accountFilesExists.length || form.accountFiles.length"
                class="request-form__content-files"
              >
                <FileCard
                  v-for="(file, index) in form.accountFilesExists"
                  :key="index"
                  :name="file.name"
                  :size="fileSizeFormatter(+file.size)"
                  @delete="deleteFile(file, index, 'accountFiles')"
                />
                <FileCard
                  v-for="(file, index) in form.accountFiles"
                  :key="index"
                  :name="file.name"
                  :size="fileSizeFormatter(+file.size)"
                  @delete="deleteFile(file, index, 'accountFiles')"
                />
              </div>
            </ValidationProvider>
            <ValidationProvider
              class="form-group d-block"
              rules="ext:pdf,png,jpeg,jpg,msg|maxSize:200000"
              v-slot="{ errors, validate }"
            >
              <div>
                <label class="request-form__label" for="otherFiles">Прочие подтверждающие документы</label>
                <input
                  type="file"
                  multiple
                  ref="otherFiles"
                  class=" request-form__input"
                  id="otherFiles"
                  name="otherFiles"
                  @input="inputFilesHandle($event, 'otherFiles')"
                  @change="validate"
                >
              </div>
              <span :class="{ 'd-block my-2 request-form__error': true, 'text-danger': errors.length }">
                {{ errors[0] || 'Форматы pdf, jpeg, jpg, png, msg. Не более 200 Мб' }}
              </span>
              <div
                v-if="form.otherFilesExists.length || form.otherFiles.length"
                class="request-form__content-files"
              >
                <FileCard
                  v-for="(file, index) in form.otherFilesExists"
                  :key="index"
                  :name="file.name"
                  :size="fileSizeFormatter(+file.size)"
                  @delete="deleteFile(file, index, 'otherFiles')"
                />
                <FileCard
                  v-for="(file, index) in form.otherFiles"
                  :key="index"
                  :name="file.name"
                  :size="fileSizeFormatter(+file.size)"
                  @delete="deleteFile(file, index, 'otherFiles')"
                />
              </div>
            </ValidationProvider>
            <p class="request-form__text">
              Со списком обязательных документов для подтверждения списания просим ознакомиться по
              <u>
                <router-link to="/catalog/materialy-dlya-oformleniya-sto-i-rtt" target="_blank">ссылке</router-link>
              </u>
            </p>
          </div>
          <div class="request-form__actions">
            <button type="button" class="btn btn-red" @click="cancelHandle">Отменить</button>
            <button type="submit" :disabled="invalid" class="btn btn-red">Отправить зявку</button>
          </div>
        </form>
      </ValidationObserver>
    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import FileCard from "@/components/profile/requests-write-down-points/FileCard.vue";
import {defaultRequestForm} from "@/config/requestWriteDownPoints";
import cloneDeep from 'lodash.clonedeep'
import fileSizeFormatter from '@/utilities/file-size-formatter'
import AppInput from "@/app-components/app-input/AppInput.vue";

export default {
  name: 'RequestForm',
  components: {
    AppInput,
    FileCard,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Создание заявки на списание',
    },
    open: {
      type: Boolean,
      default: false
    },
    initialModel: {
      type: Object,
      default: () => (cloneDeep(defaultRequestForm))
    }
  },
  data() {
    return {
      fileSizeFormatter,
      defaultWriteDownFor: 'Списание баллов за ',
      defaultRequestForm,
      defaultForm: cloneDeep(this.initialModel),
      form: cloneDeep(this.initialModel),
    }
  },
  methods: {
    async inputFilesHandle(e, formKey) {
      this.form[formKey] = [...e.target.files];
    },
    inputWriteDownForHandle(e) {
      if (new RegExp(this.defaultWriteDownFor).test(e.target.value)) {
        this.form.writeDownFor = e.target.value;
      } else {
        this.form.writeDownFor = this.defaultWriteDownFor;
        e.target.value = this.defaultWriteDownFor;
      }
    },
    focusWriteDownForHandle() {
      if (this.form.writeDownFor === '') {
        this.form.writeDownFor = this.defaultWriteDownFor;
      }
    },
    blurWriteDownForHandle() {
      if (this.form.writeDownFor === this.defaultWriteDownFor) {
        this.form.writeDownFor = '';
      }
    },
    inputPointsHandle(e) {
      e.target.value = e.target.value.replace(/[^\d]/g,'').replace(/^0/, '') || '';
      this.form.points = e.target.value.replace(/[^\d]/g,'').replace(/^0/, '') || '';
    },
    deleteFile(file, index, formKey) {
      if (file instanceof File) {
        this.form[formKey].splice(index, 1);
        const fileList = new DataTransfer();
        this.form[formKey].forEach((file) => fileList.items.add(file));
        this.$refs[formKey].files = fileList.files;
        this.$refs[formKey].dispatchEvent(new Event('change'));
      } else {
        this.form[`filesForDelete`].push(file.id);
        this.form[`${formKey}Exists`].splice(index, 1);
      }
    },
    async submitHandle() {
      this.form.actFilesExists = [];
      this.form.otherFilesExists = [];
      this.form.accountFilesExists = [];
      this.$emit('submit', this.form);
    },
    cancelHandle() {
      this.form = { ...this.defaultForm };
      this.$emit('cancel');
    },
    outsideClickHandle(e) {
      if (e.target === this.$refs.requestOverlay && !window.getSelection()) {
        this.cancelHandle();
      }
    }
  },
  watch: {
    open(val) {
      if (val) {
        this.form = cloneDeep(this.initialModel);
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.request-form__overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .4);
}
.request-form {
  background-color: #fff;
  border-radius: 4px;
  max-height: 90vh;
  max-width: 600px;
  width: 100%;
  padding: 1.8rem;
  flex-direction: column;
  display: flex;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    color: #545454;
    &-text {
      font-size: 1.28rem;
    }
    & .btn {
      padding: 0;
      background-color: inherit;
      box-shadow: none;
    }
  }
  &__content {
    flex: 1;
    overflow: auto;
    padding: .2rem;
    &-files {
      display: flex;
      margin-top: .571rem;
      gap: .571rem;
      flex-wrap: wrap;
    }
  }
  &__input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem 0.375rem 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-top: unset !important;
    border-left: unset !important;
    border-right: unset !important;
    border-bottom: 1px solid rgba(0,0,0,.42) !important;
    outline: none;
    transition: .15s ease-in-out;
    &:focus {
      border-color: #1976d2 !important;
      box-shadow: 0 1px 0 0 #1976d2;
    }
  }
  &__error {
    min-height: 1rem;
  }
  &__text {
    font-size: 1rem;
    padding-top: 1rem;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    & .btn:not(:last-child) {
      margin-right: .571rem;
    }
  }
}
</style>
